<template>
  <div class="media">
    <iframe :src="media_src"></iframe>
  </div>
</template>

<script>
export default {
  name: 'ModelViewer',
  props: {
    media_src: String
  }
}
</script>

<style scoped lang="scss"></style>
