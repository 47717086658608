<template>
  <div class="media" v-lazy-container="{ selector: 'img' }">
    <img
      class="lazy-image"
      :data-src="media_src"
      :data-loading="media_src"
    />
  </div>
</template>

<script>
export default {
  name: 'ImageViewer',
  props: {
    media_src: String
  }
}
</script>

<style scoped lang="scss">

img[lazy='loading'] {
  filter: blur(10px);
}

img[lazy='loaded'],
img[lazy='error'] {
  filter: blur(0px);
}

</style>
