<template>
  <div class="media">
    <video controls :src="media_src"></video>
  </div>
</template>

<script>
export default {
  name: 'AudioViewer',
  props: {
    media_src: String
  }
}
</script>

<style scoped lang="scss"></style>
