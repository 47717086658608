<template>
  <section class="figure-page">
    <Loader v-if="loadingStatus.indexOf('ID') === -1 && monumentData.errorStatus === ''"/>
    <div id="errorMessage" v-if="monumentData.errorStatus === 404">
       <span class="material-icons-round">error_outline</span>
       <h1> Monument Not Found</h1>
    </div>
    <BreadcrumbNav :route="this.$route.name"  v-if="loadingStatus.indexOf('ID') >= 0"/>
    <div
      id="media-viewer"
      :class="'card-toggle__' + toggle"
      v-if="loadingStatus.indexOf('ID') >= 0"
      v-bind:style="[pdfLoaded.pageHeight !== '' ? {'max-height': maxHeightFormatted} : {}]"
    >
      <AudioViewer v-if="monumentData.body.media_type === 'audio'" :media_src="monumentData.body.media_src"/>
      <ImageViewer v-if="monumentData.body.media_type === 'image'" :media_src="monumentData.body.media_src"/>
      <ModelViewer v-if="monumentData.body.media_type === '3d'" :media_src="monumentData.body.media_src"/>
      <TextViewer v-if="monumentData.body.media_type === 'text'" :media_src="monumentData.body.media_src" :resize="toggle" :pdfLoaded="pdfLoaded"/>
      <VideoViewer v-if="monumentData.body.media_type === 'video'" :media_src="monumentData.body.media_src"/>
      <div class="figure-info card monument">
          <button :class="'card-toggle__' + toggle" v-on:click="toggleState">
            <i class="material-icons" v-if="!toggle">chevron_right</i>
            <i class="material-icons" v-if="toggle">chevron_left</i>
          </button>
          <div
            :class="'text-container scroll__' + scrollState"
            ref="scrollBox"
            v-bind:style="[scrollState ? {'overflow-y': 'scroll'} : {'overflow': 'unset'}]"
          >
          <div :class="'material-icon media-type ' + (monumentData.body.media_type === '3d' ? 'model' : monumentData.body.media_type)">
            {{ monumentData.body.media_type | mediaType }}
          </div>
          <h1 class="title" v-html="monumentData.body.title"></h1>
          <a v-if="monumentData.body.monument_type === 'curriculum'"
            :href="monumentData.body.media_src"
            :download="monumentData.body.title"
            class="button">
              <i class="material-icons">download</i>
              Download Curriculum
          </a>
          <div class="description" v-html="monumentData.body.description"></div>
          <!-- <a class="button" v-if="scrollState !== true && scrollState !== null" v-on:click="enableScroll">
            Read More <i class="material-icons">keyboard_arrow_down</i>
          </a> -->
          <hr>
          <span class="identifier material-icon location" v-if="!!monumentData.body.location" v-html="monumentData.body.location"></span>
          <span class="identifier material-icon date">{{ monumentData.body.date | formatDate(monumentData.body.date_circa) }}</span>
          <div class="source-info">
            <span :class="'source-label value_' + monumentData.body.source_rank" id="source-rank" v-if="!!monumentData.body.source_rank">{{ monumentData.body.source_rank | sourceRank }}</span>
          </div>
          <div class="source">
            <span>Source: <br/></span>
            <span v-html="monumentData.body.source"></span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AudioViewer from '@/components/AudioViewer.vue'
import ImageViewer from '@/components/ImageViewer.vue'
import ModelViewer from '@/components/ModelViewer.vue'
import TextViewer from '@/components/TextViewer.vue'
import VideoViewer from '@/components/VideoViewer.vue'
import BreadcrumbNav from '@/components/BreadcrumbNav.vue'
import Loader from '@/components/Loader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FigurePage',
  props: [],
  components: {
    AudioViewer,
    ImageViewer,
    ModelViewer,
    TextViewer,
    VideoViewer,
    BreadcrumbNav,
    Loader
  },
  data: function () {
    return {
      toggle: false,
      maxHeight: 850,
      maxHeightFormatted: '',
      scrollState: null
    }
  },
  methods: {
    toggleState: function () {
      this.toggle = !this.toggle
    },
    enableScroll: function () {
      this.scrollState = !this.scrollState
    },
    setHeight: function () {
      if (window.innerWidth > 768) {
        this.maxHeight = this.pdfLoaded.pageHeight + 200
        this.maxHeightFormatted = this.pdfLoaded.pageHeight + 200 + 'px'
      } else {
        this.maxHeightFormatted = 'unset'
      }
    }
  },
  computed: mapGetters([
    'monumentData',
    'loadingStatus',
    'pdfLoaded'
  ]),
  watch: {
    pdfLoaded: {
      // Watch for PDF Dimensions
      // If text-container is longer than PDF viewport:
      // Apply line clamp and display button to activate overflow scroll
      handler (newStatus, oldStatus) {
        this.setHeight()
        if (this.$refs.scrollBox.clientHeight > (this.maxHeight + 100) && window.innerWidth > 768) {
          console.log(this.$refs.scrollBox.clientHeight, this.maxHeight)
          this.scrollState = false
        }
        // If mobile, apply line clamp on any text over 800px
        if (window.innerWidth < 768 && this.$refs.scrollBox.clientHeight > 800) {
          this.scrollState = false
        }
      },
      deep: true
    }
  },
  mounted: function () {
    this.$store.dispatch('loadmonumentData', { type: 'id', param: this.$route.params.id })
    this.$store.dispatch('setVisited', { id: this.$route.params.id })
    if (this.monumentData.body.media_type !== 'text') {
      this.$store.dispatch('setPDFLoaded', { status: false, errorStatus: '', pageHeight: '' })
    }
  }
}
</script>

<style lang="scss">
  #errorMessage {
    margin: $margin;
    border-radius: $border-radius-outer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 768px;
    margin: $margin auto;
    padding: $margin;
    color:  $robinblue;
    span {
      font-size: 2em;
    }
  }

  a.button {
    i {
      vertical-align: sub;
    }
  }

  #app.FigurePage {
    header .logoRow img {
      width: unset;
    }
  }
  .figure-page {
    width: 100%;
    margin: 0 auto;
    position: relative;
    #media-viewer {
      background: $darkgrey;
      @media only screen and (max-width: 768px) {
        max-height: unset!important;
      }
      .media {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        padding: 1em;
        height: auto;
        &.pdf {
         max-height: 65vh;
        }
        img,
        audio,
        video,
        iframe {
          width: 100%;
          max-width: 640px;
          max-height: unset;
          margin: auto;
        }
      }
      .card.monument.figure-info {
        background: $robinblue;
        box-shadow: unset;
        border-radius: 0px;
        max-width: unset;
        overflow: unset;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
        margin: 0px;
        display: flex; /* Fallback for enforcing scroll on windows chrome */
        flex-direction: column;
        button {
          display: none;
        }
        .description {
          white-space: pre-wrap;
        }
        .button {
          @include card-button;
          display: inline-block;
          width: auto;
          background: $siena;
          color: $robinblue;
          text-decoration: none;
          margin: 0.5em 0;
          padding-left: 3em;
          padding-right: 3em;
           @media (max-width: 768px) {
            padding: 0.5em 0.5em;
          }
          &:visited { color: $robinblue; }
          &:hover {
            background: $robinblue;
            color: $siena;
          }
        }
      }
      .text-container {
        height: 100%;
        @media (max-width: 768px) {
          overflow-y: unset;
        }
        span.source-label {
          text-transform: capitalize;
        }
        &.scroll__false {
          .description {
            @include line-clamp(16, 26);
          }
        }
      }
      @media (min-width: 768px) {
        width: 100%;
        display: flex;
        overflow: hidden;
        &.card-toggle__true {
          .media {
            flex-shrink: 0;
            max-width: calc(100% - 40px);
            img,
            audio,
            video,
            iframe {
              max-width: unset;
            }
          }
          .card.monument.figure-info {
            -webkit-transform: translateX(25px);
            transform: translateX(25px);
          }
        }
        .media {
          max-width: calc(100% - 480px);
          height: auto;
          -webkit-transition: .25s linear all;
          transition: .25s linear all;
          align-items: center;
          display: flex;
          &.pdf {
            max-height: unset;
          }
        }
        .card.monument.figure-info {
          background: $robinblue;
          box-shadow: unset;
          border-radius: 0px;
          margin: 0px;
          width: 480px;
          -webkit-transition: .15s linear all;
          transition: .15s linear all;
          /*overflow: unset;*/
          box-shadow: -4px 0px 3px #0000001f;
          button {
            display: block;
            border-radius: 10px 0 0 10px;
            border: 0px;
            background: $robinblue;
            position: absolute;
            left: -30px;
            top: 2em;
            height: 60px;
            width: 30px;
            cursor: pointer;
            box-shadow: -4px 0px 3px #0000001f;
            &:focus {
              outline: none;
              border: none;
            }
            .material-icons { vertical-align: bottom; }
          }
          .text-container {
            width: 444px;
          }
        }
        @media only screen and (device-width: 768px) {
          /* For general iPad layouts */
          .media {
            max-width: calc(100% - 400px);
          }
          .card.monument.figure-info {
            width: 400px;
          }
        }
      }
    }
  }
</style>
