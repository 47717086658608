<template>
  <nav id="breadcrumb-nav">
      <ul v-if="route === 'SearchResults'">
        <li>
          <router-link :to="{ name: 'home' }">
            <i class="material-icons" id="home-icon">home</i>
          </router-link>
        </li>
        <li><i class="material-icons">chevron_right</i></li>
        <li><span>Search for “<strong>{{ term }}</strong>”</span></li>
      </ul>
      <ul v-if="route === 'TermsOfService' || route === 'PrivacyPolicy' || route === 'CookiePolicy'">
        <li>
          <router-link :to="{ name: 'home' }">
            <i class="material-icons" id="home-icon">home</i>
          </router-link>
        </li>
        <li><i class="material-icons">chevron_right</i></li>
        <li><span><strong>{{ term }}</strong></span></li>
      </ul>
      <ul v-if="route === 'FigurePage'">
        <li>
          <router-link :to="{ name: 'home' }">
            <i class="material-icons" id="home-icon">home</i>
          </router-link>
        </li>
        <li>
          <i class="material-icons">chevron_right</i>
        </li>
        <li class="no-wrap" v-if="term">
          <router-link :to="{ name: 'SearchResults', query: { term: term }}">
            <span>Search for “<strong>{{ term }}</strong>”</span>
          </router-link>
        </li>
        <li>
          <i class="material-icons"  v-if="term">chevron_right</i>
        </li>
        <li>
          <span><strong v-html="monumentData.body.title"></strong></span>
        </li>
      </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BreadcrumbNav',
  data () {
    return {
      route: this.$attrs.route
    }
  },
  computed: mapGetters([
    'term',
    'monumentData'
  ])
}
</script>

<style scoped lang="scss">

#app.TermsOfService,
#app.PrivacyPolicy,
#app.CookiePolicy,
#app.FigurePage {
 nav#breadcrumb-nav {
    width: 100%;
    margin: 0px;
    border-radius: 0px;
  }
}
nav#breadcrumb-nav {
  width: calc(100% - (#{$margin} * 2));
  margin: 0 $margin;
  min-height: 64px;
  background: $robinblue;
  border-radius: 24px;
  // font-size: 30px;
  font-size: 24px;
  text-align: left;
  padding: calc(#{$margin} / 2) $margin;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    min-height: 40px;
    padding: calc(#{$margin} / 2);
    padding-top: calc(#{$margin} / 3);
    width: 100%;
    margin: 0;
    border-radius: 0px;
  }
  * {
    color: $darkplum;
  }
  ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    display: flex;
    align-items: center;
    li {
      display: inline;
      &.no-wrap {
        white-space: nowrap;
      }
      .material-icons {
        font-size: 30px;
        vertical-align: sub;
        &#home-icon {
          font-size: 35px;
        }
      }
      a {
        text-decoration: none;
        &:visited {
          color: $darkplum;
        }
        &:hover,
        &:hover * {
          color: $siena;
        }
      }
    }
  }
  @media (max-width: 768px) {
    ul {
      display: inline;
      li {
        font-size: $smalltext_mobile;
        .material-icons,
        .material-icons#home-icon {
          font-size: 24px;
          vertical-align: bottom;
        }
      }
    }
  }
}

</style>
