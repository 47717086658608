<template>
  <div :class="'media pdf ' + pdfLoaded.status">
    <div id="document-loader" v-if="!pdfLoaded.status && pdfLoaded.errorStatus === ''">
      <div class="loading-message">
        <h2>Document Loading</h2>
      </div>
      <Loader/>
    </div>
    <div id="document-error" v-if="pdfLoaded.errorStatus !== ''">
      <div class="error-message">
        <h2>{{pdfLoaded.errorStatus}}!</h2>
      </div>
    </div>
    <PDFViewer
      v-if="pdfLoaded.errorStatus === ''"
      v-bind="{url}"
      :resize="resize"
      >
    </PDFViewer>
  </div>
</template>

<script>
import PDFViewer from '@/components/PDFViewer.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'TextViewer',
  components: {
    PDFViewer,
    Loader
  },
  props: {
    media_src: String,
    resize: Boolean,
    pdfLoaded: Object
  },
  data () {
    return {
      url: this.media_src, // a PDF
      scale: 2
    }
  }
}
</script>

<style lang="scss">

#document-loader,
#document-error {
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: calc(100vh - 200px);
  @media (max-width: 768px) {
    height: 300px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-family: "Circular Std", $font-fallback;
    font-weight: normal;
    color: $robinblue;
  }
  #loader-container {
    height: auto!important;
  }
}

#document-loader {
  @media (max-width: 768px) {
    h2 {
      display: none;
    }
  }
}

.media.pdf {
  position: relative;
  justify-content: center;
  height: calc((100vw - 480px) * 1.45);
  min-height: calc((100vw - 480px) * 1.45);
}
#media-viewer {
  @media (min-width: 768px) {
    &.card-toggle__true {
      .media.pdf {
        height: calc(100vw * 1.45);
      }
    }
  }
}

</style>
