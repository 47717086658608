<template>
  <div class="pdf-zoom">
    <a @click.prevent.stop="zoomIn" class="icon" :disabled="isDisabled"><i class="material-icons">zoom_in</i></a>
    <a @click.prevent.stop="zoomOut" class="icon" :disabled="isDisabled"><i class="material-icons">zoom_out</i></a>
  </div>
</template>

<script>

export default {
  name: 'PDFZoom',

  props: {
    scale: {
      type: Number
    },
    increment: {
      type: Number,
      default: 0.25
    },
    resize: {
      type: Boolean
    }
  },

  computed: {
    isDisabled () {
      return !this.scale
    }
  },
  methods: {
    zoomIn () {
      this.updateScale(this.scale + this.increment)
      console.log('rescaling: ', this.scale + this.increment)
    },

    zoomOut () {
      if (this.scale <= this.increment) return
      this.updateScale(this.scale - this.increment)
      console.log('rescaling: ', this.scale - this.increment)
    },

    updateScale (scale) {
      this.$emit('change', { scale })
      console.log('updating scale: ', scale)
    },

    fitWidth () {
      this.$emit('fit', 'width')
    },

    fitAuto () {
      this.$emit('fit', 'auto')
    }
  },
  watch: {
    // watch for resize card toggle
    resize: function (newVal, oldVal) {
      if (this.resize) {
        // set timeout delay to account for animation
        setTimeout(() => this.fitWidth(), 300)
      } if (!this.resize) {
        // set timeout delay to account for animation
        setTimeout(() => this.fitAuto(), 300)
      }
    }
  }
}
</script>

<style>

</style>
