<template>
  <div class="pdf-paginator">
    <template>
      <button v-on:click="count--" v-bind:disabled="count===1" class="increment"><i class="material-icons">chevron_left</i></button>

      <input
        @input="input"
        min="1"
        :max="pageCount"
        type="number"
        v-model.number="count"
        />  <span>of</span> <span>{{ pageCount }}</span>
        <button v-on:click="count++" v-bind:disabled="count===pageCount" class="increment"><i class="material-icons">chevron_right</i></button>

    </template>
  </div>
</template>

<script>
export default {
  name: 'PDFPaginator',

  props: {
    value: Number,
    pageCount: Number
  },

  computed: {
    count: {
      get () { return this.value },
      set (newValue) { this.$emit('input', newValue) }
    }
  },

  methods: {
    input (event) {
      this.$emit('input', parseInt(event.target.value, 10))
    }
  },

  watch: {
    pageCount () {
      this.$emit('input', 1)
    }
  }
}
</script>

<style lang="scss">
.pdf-paginator {
  color: $robinblue;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 0!important;
  justify-content: center;
}
.pdf-paginator input {
  width: 2em;
  padding: 0.3em;
  text-align: center;
}

.media .pdf-viewer header button {
  padding: 0;
}

.media .pdf-viewer header button:disabled {
  cursor: default;
}

.media .pdf-viewer header .pdf-paginator button i {
  margin-right: 0px;
}

.pdf-paginator * {
  margin-right: 6px;
}

.pdf-paginator input::-webkit-outer-spin-button,
.pdf-paginator input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
 .pdf-paginator input[type=number] {
  -moz-appearance: textfield;
}
 .pdf-paginator button:disabled {
  border: 3px solid #696767!important;
  color: #696767!important;
  pointer-events: none;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;  /* Firefox all */
  -ms-user-select: none;  /* IE 10+ */
  user-select: none;
}
</style>
