<template>
  <div class="pdf-viewer" v-bind:style="docAttrs" >
    <header v-if="pageCount" class="pdf-viewer__header box-shadow">
      <div class="header-group">
        <div class="pdf-preview-toggle header-item">
          <a @click.prevent.stop="togglePreview" class="icon">
          <i class="material-icons">view_list</i></a>
        </div>
        <PDFZoom
          :scale="scale"
          @change="updateScale"
          @fit="updateFit"
          :resize="resize"
          class="header-item"
          />
      </div>
      <PDFPaginator
        v-model="currentPage"
        :pageCount="pageCount"
        class="header-item"
        />
      <slot name="header"></slot>
    </header>

    <PDFData
      v-bind:style="docAttrs"
      class="pdf-viewer__main"
      :url="url"
      @page-count="updatePageCount"
      @page-focus="updateCurrentPage"
      @document-rendered="onDocumentRendered"
      @document-errored="onDocumentErrored"
      >
      <template v-slot:preview="{pages}">
        <PDFPreview
          v-show="isPreviewEnabled"
          class="pdf-viewer__preview"
          v-bind="{pages, scale, currentPage, pageCount, isPreviewEnabled}"
          />
      </template>

      <template v-slot:document="{pages}">
        <PDFDocument
          class="pdf-viewer__document"
          :class="{ 'preview-enabled': isPreviewEnabled }"
          v-bind="{pages, scale, optimalScale, fit, currentPage, pageCount, isPreviewEnabled, docAttrs}"
          @scale-change="updateScale"
          @update="handleUpdate"
          />
      </template>
    </PDFData>
  </div>
</template>

<script>

import PDFDocument from '@/components/PDFDocumentAdv.vue'
import PDFData from '@/components/PDFData.vue'
import PDFPaginator from '@/components/PDFPaginator.vue'
import PDFPreview from '@/components/PDFPreview.vue'
import PDFZoom from '@/components/PDFZoom.vue'

function floor (value, precision) {
  const multiplier = Math.pow(10, precision || 0)
  return Math.floor(value * multiplier) / multiplier
}

export default {
  name: 'PDFViewer',

  components: {
    PDFDocument,
    PDFData,
    PDFPaginator,
    PDFPreview,
    PDFZoom
  },

  props: {
    url: String,
    resize: Boolean
  },

  data () {
    return {
      scale: undefined,
      optimalScale: undefined,
      fit: undefined,
      currentPage: 1,
      pageCount: undefined,
      isPreviewEnabled: false,
      height: '1000px'
    }
  },

  computed: {
    docAttrs () {
      return this.height
    }
  },

  methods: {
    handleUpdate (info) {
      this.height = info
    },

    onDocumentRendered () {
      this.$emit('document-errored', this.url)
    },

    onDocumentErrored (e) {
      this.$emit('document-errored', e)
      this.$store.dispatch('setPDFLoaded', { errorStatus: e.text })
    },

    updateScale ({ scale, isOptimal = false }) {
      const roundedScale = floor(scale, 2)
      if (isOptimal) this.optimalScale = roundedScale
      this.scale = roundedScale
    },

    updateFit (fit) {
      this.fit = fit
    },

    updatePageCount (pageCount) {
      this.pageCount = pageCount
    },

    updateCurrentPage (pageNumber) {
      this.currentPage = pageNumber
    },

    togglePreview () {
      this.isPreviewEnabled = !this.isPreviewEnabled
    }
  },

  watch: {
    url () {
      this.currentPage = undefined
    }
  },

  mounted () {
    document.body.classList.add('overflow-hidden')
  }
}
</script>

<style lang="scss">
.media .pdf-viewer {
  margin-bottom: 150px;
}
.media .pdf-viewer header {
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  padding: calc(#{$margin} / 2);
  padding-right: 0px;
  position: relative;
  z-index: 3;
  position: absolute;
  top: 0px;
  left: 0px;
  background: transparent!important;
  @media (max-width: 768px){
    padding: calc(#{$margin} / 2);
  }
  .header-group {
    display: flex;
    align-items: center;
  }
  .header-item {
    margin-right: 1.5em;
    @media (max-width: 980px) {
      margin: 0 .5em;
    }
    a, button {
      float: left;
      cursor: pointer;
      display: block;
      border: 3px $robinblue solid;
      background: transparent;
      color: $robinblue;
      font-weight: bold;
      line-height: 1.5em;
      width: 1.5em;
      height: 1.5em;
      font-size: 1.5em;
      box-shadow: 0px 0px 3px rgba(25, 19, 48, 0.3);
      border-radius: 8px;
      margin-right: 6px;
      @media (max-width: 980px) {
        width: 1.3em;
        height: 1.3em;
        font-size: 1.3em;
        line-height: 1.3em;
      }
      &:hover {
        border: 3px $siena solid;
        color: $siena;
      }
      i {
        width: 1.4em;
        height: 1.4em;
        text-align: center;
        border: unset;
        @media (max-width: 980px) {
          width: 1.1em;
          height: 1.1em;
          font-size: 1em;
          line-height: 1em;
        }
      }
    }
    button {
      &.increment {
        margin-left: 6px;
      }
      &:focus {
        outline: none;
      }
    }
    input {
      background: $robinblue;
      border: 0;
      outline: 0;
      border-radius: 8px;
      height: 2.25em;
      width: 2.3em;
      @media (max-width: 980px) {
        width: 2em;
        height: 1.6em;
        line-height: 1.6em;
      }
    }
  }
}

.pdf-preview-toggle {
  @media (max-width: 768px){
    display: none;
  }
}

.pdf-viewer .pdf-viewer__document,
.pdf-viewer .pdf-viewer__preview {
  top: 70px;
  @media (max-width: 768px){
    top: 60px;
  }
}

.pdf-viewer__preview {
  display: block;
  width: 12%;
  right: 90%;
  padding: 1em 0 1em 1em;
}

.pdf-viewer__document {
  top: 70px;
  left: 0;
}

.pdf-viewer__document.preview-enabled {
  width: 90%;
  left: 10%;
}

@media print {
  header {
    display: none;
  }
}

</style>
